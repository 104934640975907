import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import List from '../../components/List'
import { getDataFromServer, pluck } from '../../shared/utilities'
import LoadingIcon from '../../components/loadingIcon'
import LinksMenu from '../../components/LinksMenu'
import SearchInput from '../../components/SearchInput'
import LayoutListView from '../../components/LayoutListView'

const Members = ({ location }) => {
  const configID = '5RcwnKtlcfWdZ6K1xJA14M'
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [selectedValue, setSelectedValue] = useState('All')
  const [selectedStateValue, setStateSelectedValue] = useState('All')

  const MEMBERSHIP_KEY = 'membership'
  const memberships = pluck(data, MEMBERSHIP_KEY)

  const STATE_KEY = 'addressState'
  const states = pluck(data, STATE_KEY)

  useEffect(() => {
    const endpointEvents = 'https://www.missionone.com.au/api/missions/?searchType=MI'
    const token = process.env.GATSBY_MISSION_ONE_API_TOKEN

    if (!token) console.log('No MissionOne Token - fetch will fail')
    // @TODO - Add error handling

    getDataFromServer(endpointEvents, token).then(members => {
      setData(members.filter(item => item[MEMBERSHIP_KEY] !== "Non-Member"))
      setFilteredData(members)
    })
  }, [])

  function filterData(key, value) {
    const membersData = data.filter(item => item[key] === value)
    setSelectedValue(value)
    setStateSelectedValue(value)
    setFilteredData(membersData)
  }

  function resetFilter() {
    setSelectedValue('All')
    setStateSelectedValue('All')
    setFilteredData(data)
  }

  function handleClick(e) {
    const value = e.currentTarget.dataset.id
    filterData(MEMBERSHIP_KEY, value)
    setStateSelectedValue('All')
  }

  function handleStateClick(e) {
    const value = e.currentTarget.dataset.id
    filterData(STATE_KEY, value)
    setSelectedValue('All')
  }

  function handleChangeSearchBar(e) {
    const dataListCopy = [...data]
    let searchList = []

    if (e.target.value !== '') {
      searchList = dataListCopy.filter(item => {
        const itemTitle = item.name.toLowerCase()
        const searchText = e.target.value.toLowerCase().trim()
        return itemTitle.includes(searchText)
      })
    } else {
      searchList = [...dataListCopy]
    }
    setFilteredData(searchList)
  }

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <SearchInput handleChangeSearchBar={handleChangeSearchBar} />

      {data.length === 0 ? (
        <div className="text-center m-50-auto">
          <LoadingIcon />
        </div>
      ) : (
        <>
          <LinksMenu
            title="All"
            items={memberships}
            type="membership"
            handleClick={handleClick}
            resetFilter={resetFilter}
            selectedValue={selectedValue}
          />
          <LinksMenu
            title="All States"
            items={states}
            type="state"
            handleClick={handleStateClick}
            resetFilter={resetFilter}
            selectedValue={selectedStateValue}
          />
          <List data={filteredData} itemType="members" className="items-container" />
        </>
      )}
    </LayoutListView>
  )
}

export default Members

Members.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
